@import url('https://fonts.googleapis.com/css2?family=Lora&family=PT+Sans+Caption:wght@400;700&display=swap');

body {
    font-family: 'PT Sans Caption', sans-serif;
}

.bg-gradient-primary {
    background-image: linear-gradient(270deg, #218DFC 0%, #1FE0E6 52%, #2AF598 100%);
}
.text-gradient-primary {
    background: linear-gradient(270deg, #218DFC 0%, #1FE0E6 52%, #2AF598 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.form__options__option__active {
    box-shadow: 0 2px 16px 0 rgba(51,74,192,0.20);
    background: white !important;
}


/* Transitions */
.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms;
}
.fade-exit {
    opacity: 1;
    display: none;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 400ms;
}


@keyframes slidein {
    0%   {
        opacity: 0;
        transform: translateY(10%);
    }
    50%  {opacity: 1; transform: translateY(5%)}
    100% {opacity: 1;
        transform: translateY(0%)}
}
